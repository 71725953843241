/**
 *
 * @param {Object} allStrapiFees
 * @param {string} feeName
 * @returns {?[]}
 */
function getFeeByName(allStrapiFees, feeName) {
  const fees = allStrapiFees.nodes;
  for (let i = 0; i < fees.length; i++) {
    const fee = fees[i];
    if (fee.Name === feeName) {
      return fee;
    }
  }
  return null;
}

export default getFeeByName;
